<template>
    <!-- Modal -->
  <div v-if="showModal" class="modal absolute max-w-5xl xl:w-6/12 lg:w-8/12 w-11/12 z-30 mx-auto bg-slate-900 flex flex-col shadow-2xl rounded-md ">
    <!-- Modal header -->
    <div class="hidden p-6 border-b-4 border-gray-800 text-xl font-bold text-yellow-400 text-center uppercase"></div>
    <!-- ./Modal header -->
    
    <!-- Modal body -->
    <iframe class="w-full h-full aspect-video bg-black" width="640" height="360" :src="'https://www.youtube.com/embed/' + videoID + '?autoplay=1'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <!-- ./Modal body -->
    
    <!-- Modal footer -->
    <div class="border-t-4 border-gray-800 p-3 flex justify-center">
      <button @click="$emit('toggle-modal')" class="btn btn-black flex items-center">
        {{ $t('btn.close') }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <!-- ./Modal footer -->
  </div>
  <!-- ./Modal -->
</template>

<script>
export default {
  name: "AppModal",
  props: {
    showModal: Boolean,
    videoID: String
  }
}
</script>

<style>
</style>