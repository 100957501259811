export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "btn": {
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch it"])},
    "showreel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch ShowReel"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Gábor"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CV"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send it"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "backToTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to top"])}
  },
  "field": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
  },
  "view": {
    "projects": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether it's a logo display, a YouTube intro, a music video, a smartphone application (UI) presentation, or creative advertising, I create animations in <span class='highlight'>Adobe After Effects</span>. Here are some of my <span class='highlight'>featured projects</span> from my work."])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "partner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A couple of <span class='highlight'>featured partners</span> for whom I have already worked with."])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A few words about myself"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I'm <span class='highlight'>Gábor Kerényi</span>. For me <span class='highlight'>Motion Design</span> is the intersection of all areas of my life, where I can be complete and myself."])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The university years were extremely defining for me. I was able to study art history, philosophy, film theory and film history together with practice, first at the University of Pécs and then at Eötvös Loránd University. I was able to live my passion for creation at filmmaking and due to my personal interest, also at analog photography. Creation within the digital world was also attractive for me, so I was also immersed in web programming. After university, I gained significant marketing experience in my work, and gaining great practice in addressing people also offline and online area. In addition, I have been involved in the development of our family business at all levels.<br><br>That's why I'm a constantly active creator. I have been following the exciting journey of Motion Designers for more than 3 years now, in which I can utilize all the knowledge I have acquired so far, be it my visual vision, my storytelling sensitivity, my marketing vein, or my programming skills."])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the age of 34, I have 6 years of film university education, 5 years of marketing, several years of web programming and 3 years of motion designer experience."])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to me!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message including your contact information and <span class='highlight'>I'll contact you!</span>"])},
      "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or by phone"])}
    }
  }
}