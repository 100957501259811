const projects = [{
    id: 1,
    client: 'FoodPanda',
    titleEN: 'Web Game App UI animations',
    titleHU: 'Web Game App UI animációk',
    typeEN: 'UI animations',
    typeHU: 'UI animáció',
    duration: '2:42',
    img: require('@/assets/projects/pandarun.webp'),
    png: require('@/assets/projects/pandarun.png'),
    webm: require('@/assets/projects/pandarun.webm'),
    videoID: 'Lf-ftjCJVPg'
  },
  {
    id: 2,
    client: 'Gönci Barack Ünnep',
    titleEN: '3D, vector intro for event',
    titleHU: '3D-s, vektor rendezvény intro',
    typeEN: 'Logo animation',
    typeHU: 'Logó animáció',
    duration: '0:06',
    img: require('@/assets/projects/gbu.webp'),
    png: require('@/assets/projects/gbu.png'),
    webm: require('@/assets/projects/gbu.webm'),
    videoID: '0p-2izq5YgE'
  },
  {
    id: 3,
    client: 'Béke-Kör',
    titleEN: 'Mobile app announcement',
    titleHU: 'Mobilalkalmazás bejelentő videó',
    typeEN: 'Application presentation',
    typeHU: 'Alkalmazás bemutató',
    duration: '0:26',
    img: require('@/assets/projects/bekekor.webp'),
    png: require('@/assets/projects/bekekor.png'),
    webm: require('@/assets/projects/bekekor.webm'),
    videoID: 'np2qPckBDpQ'
  },
  {
    id: 4,
    client: 'Vastag Csaba',
    titleEN: 'Hungarian music video',
    titleHU: '"Háttal áll a lelked" c. videóklip',
    typeEN: 'Music Video',
    typeHU: 'Videóklip',
    duration: '2:42',
    img: require('@/assets/projects/vastagcsaba.webp'),
    png: require('@/assets/projects/vastagcsaba.png'),
    webm: require('@/assets/projects/vastagcsaba.webm'),
    videoID: 'WiQXKVUhz_E'
  },
  {
    id: 5,
    client: 'Szemeszter Optika',
    titleEN: 'Loyalty Point Collector application',
    titleHU: 'Hűségpontgyűjtő alkalmazás',
    typeEN: 'Application showcase',
    typeHU: 'Alkalmazás bemutató',
    duration: '0:35',
    img: require('@/assets/projects/szemeszter.webp'),
    png: require('@/assets/projects/szemeszter.png'),
    webm: require('@/assets/projects/szemeszter.webm'),
    videoID: 'HgN3IWn84v0'
  },
  {
    id: 6,
    client: 'Játékszeres',
    titleEN: 'YouTube Channel intro',
    titleHU: 'YouTube csatorna kezdőanimáció',
    typeEN: 'YouTube intro',
    typeHU: 'YouTube intro',
    duration: '0:08',
    img: require('@/assets/projects/jatekszeres.webp'),
    png: require('@/assets/projects/jatekszeres.png'),
    webm: require('@/assets/projects/jatekszeres.webm'),
    videoID: '-ForHNwJaNk'
  },
  {
    id: 7,
    client: 'Reál Élelmiszer',
    titleEN: 'Presentation of a competition',
    titleHU: 'Osztálykassza pályázat bemutatása',
    typeEN: 'Explainer video',
    typeHU: 'Explainer video',
    duration: '3:54',
    img: require('@/assets/projects/osztalykassza.webp'),
    png: require('@/assets/projects/osztalykassza.png'),
    webm: require('@/assets/projects/osztalykassza.webm'),
    videoID: 'vwmcCnA34LU'
  },
  {
    id: 8,
    client: 'SPAR Magyarország',
    titleEN: 'Social media competion',
    titleHU: '"Élménykommandó" játék',
    typeEN: 'Promotion video',
    typeHU: 'Promóciós videó',
    duration: '0:35',
    img: require('@/assets/projects/elmenykommando.webp'),
    png: require('@/assets/projects/elmenykommando.png'),
    webm: require('@/assets/projects/elmenykommando.webm'),
    videoID: 'R5_n4PfiWKg'
  },
  {
    id: 9,
    client: 'Játéknet',
    titleEN: 'Christmass TV commercial',
    titleHU: 'Karácsonyi TV reklám',
    typeEN: 'TV Commercial',
    typeHU: 'TV Reklám',
    duration: '0:30',
    img: require('@/assets/projects/jateknet.webp'),
    png: require('@/assets/projects/jateknet.png'),
    webm: require('@/assets/projects/jateknet.webm'),
    videoID: 'bu7IYkbA-GY'
  },
];

export default projects;