<template>
<section class="text-gray-400 bg-gray-900 body-font rounded-3xl lg:pb-16 pb-8">
  <div class="container w-full xl:w-10/12 lg:pt-16 pt-8 mx-auto px-2">
    <div class="flex flex-col text-center w-full mb-10">
      <h1 class="sm:text-3xl title-font mb-4 text-2xl text-white">{{ $t('view.projects.title') }}</h1>
      <p class="xl:w-4/5 mx-auto leading-relaxed text-base px-2 sm:px-7 md:px-5" v-html="$t('view.projects.description')"></p>

    </div>
  <ProjectsFeatures class="hidden"/>
  <Projects @toggle-modal="toggleModal"/>
  </div>
  <AboutPartners />
  <div class="container m-auto flex flex-col md:flex-row justify-center items-center mt-8">
      <!-- <router-link to="/projects" class="w-10/12 md:max-w-xs mx-2 mb-2 md:mb-0"> -->
        <button class="btn btn-black text-gray-400 border-black mr-3 w-10/12 md:max-w-xs" @click="backToTop">
          {{ $t('btn.backToTop') }}
        </button>
      
  </div>
</section>
</template>

<script>
import Projects from "@/components/projects/Projects";
import ProjectsFeatures from "@/components/projects/Features.vue";
import AboutPartners from "../components/about/Partners.vue";


export default {
  name: "ProjectsView",
  components: {
    Projects,
    ProjectsFeatures,
    AboutPartners
  },
  methods: {
    toggleModal (data) {
        this.$emit('toggle-modal', data)
      },
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
