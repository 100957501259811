<template>
<div class="grid grid-cols-1 md:grid-cols-2 gap-6 md:px-4 lg:grid-cols-3 lg:gap-8 xl:px-0">
  <div class="mx-auto overflow-hidden relative rounded-lg border-2 border-transparent hover:border-gray-700 bg-gray-800 group" v-for="project in projects" :key="project.id">
    <video autoplay loop class="opacity-0 absolute group-hover:opacity-100">
        <source :src="project.webm" type="video/webm">
    </video>
    <!-- <img class="w-full mx-auto rounded-t-lg opacity-0 absolute group-hover:opacity-100" :src="project.webm" :alt="project.client" :title="project.client + ' - ' + project.typeHU" /> -->
    <!-- <img class="w-full mx-auto rounded-t-lg" :src="project.img" :alt="project.client" :title="project.client + ' - ' + project.typeHU" /> -->

    <picture class="w-full mx-auto rounded-t-lg" :alt="project.client" :title="project.client + ' - ' + project.typeHU">
        <source :srcset="project.img" type="image/webp">
        <source :srcset="project.png" type="image/png">
        <img :src="project.png">
    </picture>

    <div class="flex items-center justify-between px-6 py-3 bg-black bg-opacity-20 mx-auto">

      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="#fff">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>
        <h1 class="mx-3 text-base font-semibold text-white">{{ this.$i18n.locale == 'hu' ? project.typeHU : project.typeEN }}</h1>
      </div>

      <p class=" text-sm text-gray-400">{{ project.duration }}</p>

    </div>

    <div class="px-6 py-4 flex flex-col justify-center text-gray-100 text-opacity-50">

      <div class="flex mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
        </svg>
        <p class=" text-sm font-semibold text-gray-200">{{ project.client }}</p>
      </div>
      <div class="flex mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <p class=" text-sm italic">{{ this.$i18n.locale == 'hu' ? project.titleHU : project.titleEN }}</p>
      </div>

      <button class="btn btn-yellow bg-gray-900 px-16 mx-auto" @click="$emit('toggle-modal', project.videoID )">{{ $t('btn.watch') }}</button>
      
    </div>
  </div>
</div>
</template>

<script>
import projects from '../../data/projects';

export default {
  name: "AboutPartners",
  data: () => {
    return {
      projects
    };
  },
}
</script>

<style>

</style>
