<template>
  <section class="container py-10 xl:w-10/12 mx-auto">
    <h2 class="
              sm:text-3xl
              text-2xl
              font-medium
              title-font
              mb-4
              mt-8
              text-white text-center
            ">
      {{ $t('view.projects.partners') }}
    </h2>
    <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-center mb-6 px-10 sm:px-7 md:px-5" v-html="$t('view.projects.partner-desc')"></p>
    <div class="flex items-center justify-center">
      <div class="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div class="w-full max-w-xs text-center" v-for="partner in partners" :key="partner.id">
          <img class="w-full mx-auto rounded-lg opacity-70" :src="partner.imgW" :alt="partner.title" :title="partner.title"  />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import partners from '../../data/partners';

export default {
  name: "AboutPartners",
  data: () => {
    return {
      partners
    };
  },
}
</script>

<style>

</style>
