<template>
  <div :class="[showSidebar ? '' : '-translate-x-full'] + ' flex flex-col absolute inset-y-0 left-0 h-full w-3/4 bg-black px-10 py-16 z-30 transform duration-200 ease-in-out lg:hidden'">
      <nav class="uppercase flex flex-col text-lg font-bold">
        <router-link to="/" @click="$emit('toggle-sidebar')" class="text-gray-400 hover:text-yellow-500 py-5">{{ $t('nav.home') }}</router-link>
        <router-link to="/projects" @click="$emit('toggle-sidebar')" class="text-gray-400 hover:text-yellow-500 py-5">{{ $t('nav.projects') }}</router-link>
        <router-link to="/about" @click="$emit('toggle-sidebar')" class="text-gray-400 hover:text-yellow-500 py-5">{{ $t('nav.about') }}</router-link>
        <router-link to="/contact" @click="$emit('toggle-sidebar')" class="text-gray-400 hover:text-yellow-500 py-5">{{ $t('nav.contact') }}</router-link>
      </nav>
      <div class="flex content-start mt-6">
        <button @click="this.$i18n.locale = 'en'" :class="[this.$i18n.locale == 'en' ? 'bg-yellow-500' : 'bg-slate-800']" class="text-white px-8 mr-5 btn">EN</button> | <button @click="this.$i18n.locale = 'hu'" :class="[this.$i18n.locale == 'hu' ? 'bg-yellow-500' : 'bg-slate-800']" class="text-white px-8 mr-5 btn">HU</button>
      </div>
  </div>
</template>

<script>
export default {
  name: "AppSidebar",
  props: {
    showSidebar: Boolean
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>
