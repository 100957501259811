<template>
  <section class="text-gray-400 bg-gray-900 body-font rounded-3xl">
    <div class="container px-5 lg:py-16 py-8 mx-auto flex flex-col">
       <h1
          class="
            sm:text-3xl
            text-2xl
            font-medium
            title-font
            mb-4
            text-white text-center
          "
        >
           {{ $t('view.about.title') }}
        </h1>
        <p v-html="$t('view.about.description')" class="lg:w-2/3 mx-auto leading-relaxed text-base text-center"></p>
      <AboutMe />

      <div class="container m-auto flex flex-col md:flex-row justify-center items-center mt-8">
        <!-- <router-link to="/projects" class="w-10/12 md:max-w-xs mx-2 mb-2 md:mb-0"> -->
          <button class="btn btn-black text-gray-400 border-black mr-3 w-10/12 md:max-w-xs" @click="backToTop">
            {{ $t('btn.backToTop') }}
          </button>
        
    </div>
    </div>
  </section>
</template>

<script>
import AboutMe from "../components/about/AboutMe.vue";

export default {
name: "AboutView",
components: {
  AboutMe
  },
  methods: {
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>