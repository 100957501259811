<template>
<header class="text-gray-400 body-font lg:pb-8">
  <div class="flex w-full pb-5 justify-between items-center">
    <router-link to="/">
      <a class="flex title-font font-medium items-center h-100">
        <IconLogo class="w-60 pl-5" />
      </a>
    </router-link>
    <nav class="hidden lg:flex md:mx-auto flex-wrap items-center justify-center uppercase text-sm">
      <router-link to="/" class="mr-8 p-2 border-b-2 border-transparent hover:border-black text-black">{{ $t('nav.home') }}</router-link>
      <router-link to="/projects" class="mr-8 p-2 border-b-2 border-transparent hover:border-black text-black">{{ $t('nav.projects') }}</router-link>
      <router-link to="/about" class="mr-8 p-2 border-b-2 border-transparent hover:border-black text-black">{{ $t('nav.about') }}</router-link>
      <router-link to="/contact" class="mr-8 p-2 border-b-2 border-transparent hover:border-black text-black">{{ $t('nav.contact') }}</router-link>
    </nav>
    <div class="lg:flex items-center hidden">
      <button @click="this.$i18n.locale = 'en'" :class="[this.$i18n.locale == 'en' ? 'text-yellow-200' : 'text-black']" class="px-2 py-1">EN</button> <span class="text-black">|</span> <button @click="this.$i18n.locale = 'hu'" :class="[this.$i18n.locale == 'hu' ? 'text-yellow-200' : 'text-black']" class="text-white px-2 py-1">HU</button>
    </div>
    <button @click="$emit('toggle-sidebar')" class="rounded lg:hidden flex p-6">
        <div v-show="showSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#000" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div v-show="!showSidebar">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="#000">
          <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
        </svg>
        </div>
    </button>
  </div>
</header>
</template>

<script>
import IconLogo from "@/components/icons/IconLogo.vue";

export default {
  name: "AppHeader",
  components: {
    IconLogo
  },
  props: {
    showSidebar: Boolean
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>
