<template>

  <div id="wrapper">
    <AppSidebar :showSidebar="showSidebar" @toggle-sidebar="toggleSidebar"/>
    <div @click="hideOverlay" class="overlay" :class="[showSidebar || showModal ? ' inset-0': '']"></div>
    <!-- App header -->
    <AppHeader @toggle-sidebar="toggleSidebar" :showSidebar="showSidebar"/>

    <RouterView @toggle-modal="toggleModal"/>

    <!-- App footer -->
    <AppFooter />
  </div>

  <div class="modal-wrapper w-full absolute justify-center mt-10 flex inset-0" :class="[showModal ? ' z-30': '-z-10']">
      <AppModal v-show="showModal" :videoID="this.videoID" :showModal="this.showModal" @toggle-modal="hideOverlay"/>
    <div @click="hideOverlay" class="absolute" :class="[showModal ? ' inset-0': '']"></div>
  </div>

</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import AppFooter from "@/components/shared/AppFooter.vue";
import AppSidebar from "@/components/shared/AppSidebar.vue";
import AppModal from "@/components/shared/AppModal.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    AppModal
  },
  data() {
    return {
      showSidebar: false,
      showModal: false,
      videoID: ''
    };
  },
  methods: {
    toggleSidebar () {
      this.showSidebar = !this.showSidebar
    },
    toggleModal (video) {
      this.showModal = !this.showModal
      if (video != this.videoID) {
        this.videoID = video
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    hideOverlay () {
      this.showSidebar = false;
      this.showModal = false;
      this.videoID = ''
    }
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');

body {
  @apply bg-gray-900 relative;
  /* @apply bg-yellow-300; */
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
  background-color: #fbb91c;
    background-image: linear-gradient(
    225deg,
    #fbb91c 0%,
    #fad638 30%,
    #ffe270 55%,
    #fbb91c 120%
  )
  /* overflow: clip; */
}

.btn {
  @apply m-1 rounded px-5 py-4 shadow-lg border-l-2 active:border-l-0 active:border-r-2 border-b-4 active:border-t-4 active:border-b-0 uppercase font-bold text-sm;
}

.btn-yellow {
  @apply bg-yellow-400 border-yellow-500 text-white;
}

.btn-black {
  @apply  bg-gray-800 border-gray-900 text-yellow-300;
}

#app {
  @apply relative;
  min-height: 100vh;
}

.overlay {
  @apply bg-black bg-opacity-40 absolute z-20;
  content: "";
}

body::after {
  background: linear-gradient(0deg, rgba(0,0,0,1) 38%, rgba(255,255,255,0) 38%);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  body::after {
    background: linear-gradient(0deg, rgba(0,0,0,1) 51%, rgba(255,255,255,0) 51%);
  }
}

#wrapper {
  max-width: 1280px;
  @apply px-3 sm:px-8 py-8 mx-auto;

  font-weight: normal;
}

.highlight {
  @apply text-yellow-400 font-bold;
}


</style>
