import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import "./index.css";

createApp(App).use(i18n).use(router).use(VueGtag, {
  config: { id: "G-12761EQR02" }
}).mount("#app");
