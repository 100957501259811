const partners = [{
    id: 1,
    title: 'Bemind',
    imgB: require('@/assets/logos/bemind-b.png'),
    imgW: require('@/assets/logos/bemind-w.png'),
  },
  {
    id: 2,
    title: 'Spar Magyarország',
    imgB: require('@/assets/logos/spar-b.png'),
    imgW: require('@/assets/logos/spar-w.png'),
  },
  {
    id: 3,
    title: 'JátékNet',
    imgB: require('@/assets/logos/jateknet-b.png'),
    imgW: require('@/assets/logos/jateknet-w.png'),
  },
  {
    id: 4,
    title: 'Játékszeres',
    imgB: require('@/assets/logos/jatekszeres-b.png'),
    imgW: require('@/assets/logos/jatekszeres-w.png'),
  },
  {
    id: 5,
    title: 'Cerbona',
    imgB: require('@/assets/logos/cerbona-b.png'),
    imgW: require('@/assets/logos/cerbona-w.png'),
  },
  {
    id: 6,
    title: 'Formatex',
    imgB: require('@/assets/logos/formatex-b.png'),
    imgW: require('@/assets/logos/formatex-w.png'),
  },
  {
    id: 7,
    title: 'Szemeszter',
    imgB: require('@/assets/logos/szemeszter-b.png'),
    imgW: require('@/assets/logos/szemeszter-w.png'),
  },
  {
    id: 8,
    title: 'JatekBolt.hu',
    imgB: require('@/assets/logos/jatekbolt-b.png'),
    imgW: require('@/assets/logos/jatekbolt-w.png'),
  },
];

export default partners;