<template>
<div class="block mt-5 sm:mt-10 mb-5">
  <!-- About profile image -->
  <div class="w-full xl:w-10/12 m-auto">
    <img src="@/assets/cv-bw.jpg" class="rounded-xl w-96 m-auto block md:float-left md:mr-10 md:mb-3 mb-10" alt="" />
    <p class="mb-4 text-ternary-dark dark:text-ternary-light" v-html="$t('view.about.paragraph')"></p>
  </div>
</div>

 <div class="p-8 rounded text-center text-lg mt-5 w-full xl:w-10/12 m-auto border-2 border-yellow-400 bg-black bg-opacity-30">
    <p class="leading-relaxed mb-3 font-bold text-gray-300">{{ $t('view.about.summary') }}</p>
    <a :href="'/files/KerenyiGabor_CV_' + [this.$i18n.locale == 'hu' ? 'HUN.pdf': 'ENG.pdf']" download="GaborKerenyi_CV.pdf" class="btn bg-yellow-500 border-yellow-600 text-white inline-block mt-3">
    <!-- <a href="/files/KerenyiGabor_CV_HU.pdf" download="GaborKerenyi_CV.pdf" class="btn bg-yellow-500 border-yellow-600 text-white inline-block mt-3"> -->
      {{ $t('btn.cv') }}
    </a>
</div>

</template>

<script>
export default {
  name: "AboutMe"
}
</script>

<style>

</style>
