<template>
  <section class="text-gray-400 bg-gray-900 body-font relative rounded-3xl">
    <div class="container px-5 lg:py-16 py-8 mx-auto">
      <div class="flex flex-col text-center w-full mb-12">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
           {{ $t('view.contact.title') }}
        </h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base"  v-html="$t('view.contact.description')"></p>
      </div>
      <div class="lg:w-1/2 md:w-2/3 mx-auto">
        <form @submit.prevent="sendEmail" class="hidden">
          <div class="flex flex-wrap -m-2">
            <div class="p-2 md:w-1/2 w-full">
              <div class="relative">
                <label for="name" class="leading-7 text-sm text-gray-400"
                  >{{ $t('field.name') }}</label
                >
                <input
                  type="text"
                  id="name"
                  name="name"
                  v-model="fromName"
                  class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-yellow-500 focus:bg-gray-900 focus:ring-2 focus:ring-yellow-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div class="p-2 md:w-1/2 w-full">
              <div class="relative">
                <label for="email" class="leading-7 text-sm text-gray-400"
                  >{{ $t('field.email') }}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  v-model="fromEmail"
                  class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-yellow-500 focus:bg-gray-900 focus:ring-2 focus:ring-yellow-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out peer invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-900"
                />
                <p
                  class="mt-2 hidden peer-invalid:block text-red-600 text-sm"
                  >Kérlek érvényes email címet adj meg!</p
                >
              </div>
            </div>
            <div class="p-2 w-full">
              <div class="relative">
                <label for="message" class="leading-7 text-sm text-gray-400"
                  >{{ $t('field.message') }}</label
                >
                <textarea
                  id="message"
                  v-model="msg"
                  name="message"
                  class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-yellow-500 focus:bg-gray-900 focus:ring-2 focus:ring-yellow-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
            </div>
            <div class="p-2 w-full">
              <input
                type="submit"
                :value="$t('btn.send')"
                class="flex mx-auto text-white bg-yellow-500 border-0 py-2 px-8 focus:outline-none hover:bg-yellow-600 rounded text-lg cursor-pointer"
              />
            </div>
            <AlertSuccess v-if="sendSuccess" />
          </div>

        </form>
                   <div
              class="p-2 w-full pt-8 mt-8 border-t border-gray-800 text-center"
            >
              <a class="text-yellow-400">motiondesign@kerenyigabor.hu</a>
              <p class="leading-normal my-5">
                {{ $t('view.contact.byphone')}} <br />+36 30 552 5466
              </p>
            </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "@emailjs/browser";

import AlertSuccess from "../components/shared/AlertSuccess.vue";

export default {
  name: "ContactView",
  components: {
    AlertSuccess,
  },
  data() {
    return {
      fromName: "",
      fromEmail: "",
      msg: "",
      sendSuccess: "",
    };
  },
  methods: {
    sendEmail() {
      const templateParams = {
        user_name: this.fromName,
        user_email: this.fromEmail,
        message: this.msg,
      };

      let template = "";

      if (this.$i18n.locale == 'hu') {
        template = "template_gv6yh0s"
      } else {
        template = "template_odywsj5"
      }

      emailjs
        .send(
          "service_yn052c8",
          template,
          templateParams,
          "utON71TkJs0fd5jBz"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            this.sendSuccess = true;
            this.fromName = "";
            this.fromEmail = "";
            this.msg = "";
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
      // let emailContent =
      //   this.fromName +
      //   " from: " +
      //   this.fromEmail +
      //   " and message: " +
      //   this.msg;
      // console.log(emailContent);
    },
  },
};
</script>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
