export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Főoldal"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektek"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rólam"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])}
  },
  "btn": {
    "watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekintés"])},
    "showreel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ShowReel mgtekintése"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektek"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerj meg"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV letöltése"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehet"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezár"])},
    "backToTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a tetejére"])}
  },
  "field": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzenet"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sablon"])}
  },
  "view": {
    "projects": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektek"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legyen szó logó megjelenítésről, YouTube intro-ról, videoklipről, mobil alkalmazás (UI) bemutatásáról, akár hirdetési kreatív megmozgatásáról, az animációkat <span class='highlight'>Adobe After Effects-ben</span> készítem el. Itt látható <span class='highlight'>pár kiemelt projekt</span> a munkáim közül."])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerek"])},
      "partner-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='highlight'>Pár kiemelt partner</span>, akiknek már készítettem munkákat."])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pár szó magamról"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szia, <span class='highlight'>Kerényi Gábor</span> vagyok. Számomra a <span class='highlight'>Motion Design</span> életem összes területének metszéspontja, amiben kiteljesedhetek és magam lehetek."])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egyetemi évek rendkívül meghatározóak voltak számomra. Művészettörténetet, filozófiát, filmelméletet és filmtörténetet tanulhattam gyakorlattal együtt először a Pécsi Tudományegyetemen, majd az ELTE-n. Az alkotás iránti szenvedélyemet a filmkészítésben és személyes érdeklődésemből adódóan az analóg fotózásban is megélhettem. A digitális világon belüli alkotás is vonzó volt számomra, így a webprogramozásban is elmerültem. Az egyetem után jelentős marketing tapasztalatra tettem szert munkám során, és nagy gyakorlatot szereztem az emberek megszólításában offline és online területen is. Emellett minden szinten részt vettem családi vállalkozásunk fejlesztésében.<br><br>Éppen ezért egy folyton aktív, cselekvő alkotó vagyok. Már több mint 3 éve járom a Motion Designerek kihívásokban bővelkedő izgalmas útját, melyben minden eddig megszerzett tudásomat kamatoztatni tudom, legyen szó akár a vizuális látásmódomról, a történetvezetési érzékenységemről, a marketinges vénámról, vagy az akadályokat leküzdő programozási készségemről."])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34 évesen 6 év filmes egyetemi képzéssel, 5 év marketinges, több éves webprogramozói és 3 év motion designer tapasztalattal rendelkezem."])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írj Nekem!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldj egy üzenetet az elérhetőségeddel és <span class='highlight'>felveszem a kapcsolatot Veled!</span>"])},
      "byphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy szóban"])}
    }
  }
}