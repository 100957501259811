<template>
<section class="text-gray-400 body-font">
  <div class="container mx-auto flex flex-col items-start mb-10">

    <div @click="watchShowreel" class="m-auto relative group cursor-pointer flex justify-center items-center lg:w-10/12 w-full">
        <div class="absolute bg-transparent z-10 m-auto">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" style="enable-background: new 0 0 58 58" xml:space="preserve" class=" w-24 h-24 group-hover:h-32 group-hover:w-32 transition-all">
                <IconPlay />
            </svg>
        </div>
        <picture class="lg:w-10/12 w-full h-auto" alt="ShowReel Thumbnail" style="border: 11px solid white; box-shadow: rgb(255 233 107 / 30%) 0px 0px 31px 21px;">
            <source srcset="../assets/showreel-thumbnail.webp" type="image/webp">
            <source srcset="../assets/showreel-thumbnail.jpg" type="image/jpeg">
            <img class="w-full h-auto" src="../assets/showreel-thumbnail.jpg">
        </picture>
        <!-- <img src="../assets/showreel-thumbnail.webp" class="lg:w-10/12 w-full" alt="ShowReel Thumbnail" style="border: 11px solid white; box-shadow: rgb(255 233 107 / 30%) 0px 0px 31px 21px;"/> -->

    </div>
  </div>
  <div class="container m-auto flex flex-col md:flex-row justify-center items-center">
      <router-link to="/projects" class="w-10/12 md:max-w-xs mx-2 mb-2 md:mb-0">
        <button class="btn btn-black mr-3 w-full">
          {{ $t('btn.projects') }}
        </button>
      </router-link>
      <router-link to="/about" class="w-10/12 md:max-w-xs mx-2 mb-2 md:mb-0">
        <button class="btn btn-black bg-gray-700 mr-3 w-full">
          {{ $t('btn.about') }}
        </button>
      </router-link>
      <router-link to="/contact" class="w-10/12 md:max-w-xs mx-2 mb-2 md:mb-0">
        <button class="btn btn-yellow bg-amber-400 border-amber-500  w-full">
          {{ $t('btn.contact') }}
        </button>
      </router-link>

    </div>
</section>
</template>

<script>
import IconPlay from "@/components/icons/IconPlay.vue";

export default {
  name: "HomeView",
  components: {
    IconPlay
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    watchShowreel() {
      this.$emit('toggle-modal', 'E2wd0_WmIyo')
      this.$gtag.event('showreel-clicked', {
        'event_category': 'documentation',
        'event_label': 'ShowReel Play Button Clicked',
        'value': 1
      })
    }
  }
}
</script>

<style>

</style>
