<template>
  <footer class="text-gray-400 body-font">
    <div
      class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col"
    >
      <p
        class="text-sm text-gray-400 mx-auto sm:py-2 sm:mt-0 mt-4"
      >
        © 2022 Motion Design -
        <span class="text-gray-500">kerenyigabor.hu</span>
      </p>
    </div>
  </footer>
</template>

<script>

export default {
  name: "AppFooter",
  data() {
    return {};
  },
};
</script>

<style></style>
